/* @flow */

import axios from 'axios'
// export const baseUrl = 'http://localhost:5000' //My local
export const baseUrl = 'https://padelmates-club.herokuapp.com' //My local
// export const baseUrl = 'https://cigo-startlight-86059.herokuapp.com' //My local
export const imgUrl = 'https://uploads.padelmates.co/images' //My local
// export const imgUploadUrl = 'https://uploads.padelmates.co' //My local

// export const baseUrl = 'http://localhost:5000' //My local
// export const baseUrl = 'https://padelmates-club.herokuapp.com' //My local
// export const imgUrl = 'https://uploads.padelmates.co' //My local
export var token = localStorage.getItem('token')
// var token = localStorage.getItem('token')
const headersconfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('token')
  }
}
export const sendNotificationsTor = async (obja,numberleft) => {
  console.log(obja)
  const bodyconfig = {obj:obja,numberleft:numberleft}
  let res ={}
   await axios.post(baseUrl + '/sendNotifications', bodyconfig, headersconfig)
  .then(function (response) {
        res = response.data
        console.log("response recieved insendNotifications : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}
export const postImg = async (imgData) => {
  var bodyFormData = new FormData();
  bodyFormData.set("files", imgData);
  let res ={}
  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}
   await axios.post('https://uploads.padelmates.co' + '/index.php', bodyFormData, config)
  .then(function (response) {
        res = response.data
        console.log("Image Post : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}
export const makeFinalSemiFinalTeam = async (id,type) => {

  var res = {}
  await axios.get(baseUrl + '/makeFinalSemiFinalTeam?id='+id+"&extraRound="+type, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get ALl makeFinalSemiFinalTeam : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
        res = undefined
  });
  return res;
}



export const makeExtraSemiFinalsTeam = async (id,extraRound) => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/makeExtraSemiFinalsTeam',{
    params:{
      id:id,
      extraRound:extraRound
    }
  })
  .then(function (response) {
    res = response.data
        console.log("get ALl makeExtraSemiFinalsTeam : " , response);
  })
  .catch(function (error) {
        //console.log(error)
        res = undefined
  });
  return res;
}

export const makeExtraSemiFinals = async (id,extraRound) => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/makeExtraSemiFinals',{
    params:{
      id:id,
      extraRound:extraRound
    }
  })
  .then(function (response) {
    res = response.data
        console.log("get ALl makeExtraSemiFinals : " , response);
  })
  .catch(function (error) {
        //console.log(error)
        res = undefined
  });
  return res;
}
export const deleteTournament = async (body) => {
  const bodyconfig = body
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/deleteTournament', bodyconfig, headersconfig)
  .then(function (response) {
    console.log("response recieved in deleteTournament : " , response);
  })
  .catch(function (error) {
    console.log(error)
  });
  return res;
}
export const sendNotificationsCommunications = async (obja) => {
  console.log(obja)
  const bodyconfig = {obj:obja}
  let res ={}
   await axios.post(baseUrl + '/sendNotificationsCommunications', bodyconfig, headersconfig)
  .then(function (response) {
        res = response.data
        console.log("response recieved insendNotifications : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}
export const sendWeekNotification = async (body) => {

  const bodyconfig = body
  let res ={}
  console.log(bodyconfig)
   await axios.post(baseUrl + '/sendWeekNotification',bodyconfig, headersconfig)
  .then(function (response) {
        res = response.data
        console.log("response recieved insendNotifications : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}

export const sendWeekNotificationTrain = async (body) => {

  const bodyconfig = body
  let res ={}
  console.log(bodyconfig)
   await axios.post(baseUrl + '/sendWeekNotificationTrain',bodyconfig, headersconfig)
  .then(function (response) {
        res = response.data
        console.log("response recieved insendNotifications : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}

export const sendSingle = async (title, desc, token, data) => {
  const bodyconfig = JSON.stringify({
    title: title,
    desc: desc,
    token: token,
    data: data
  })
  var res = {}
  await axios.post(baseUrl + '/single', bodyconfig, headersconfig)
    .then(function (response) {
      // console.log("response recieved in : " , response);
    })
    .catch(function (error) {
      console.log(error)

    });
  return res;
}
export const updateUserSaved = async (obj) => {
  const bodyconfig = obj
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/setTournamentPlayerInfo', bodyconfig, headersconfig)
  .then(function (response) {
        console.log("response recieved in setTournamentPlayerInfo : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });
  return res;
}
export const testApi = async (url,name) => {
  const bodyconfig = JSON.stringify({
    url: url,
    name: name,
  })
  var res = {}
  await axios.post('https://padelmates.herokuapp.com/testApi', bodyconfig, headersconfig)
  .then(function (response) {
        console.log("response recieved in testApi : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });

  return res;
}

export const getTournamentRequests = async (id) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  let res = {}

  await axios.get(baseUrl + '/getTournamentRequests?email=' + token + "&id=" + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response getTournamentRequests in : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)

    });
  return res
}

export const getTournamentQueue = async (id) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  let res = {}

  await axios.get(baseUrl + '/getTournamentQueue?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response getTournamentQueue in : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)

    });
  return res
}

export const joinTournament = async (id, user) => {
  const bodyconfig = {
    id: id,
    user: user
  }
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/joinTournament', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in joinTournament : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const nextRoundMaxicano = async (id,roundNo) => {

  var res = {}
  await axios.get(baseUrl + '/nextRoundMaxicano?id='+id+'&roundNo='+roundNo, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get nextRoundMaxicano : " , response);
  })
 .catch(function (error) {

        console.log(error)
  });
  return res;
}
export const getAccountLink = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/getAccountLink?id='+id, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get ALl getAccountLink : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
        res = undefined
  });
  return res;
}


export const isInTournamentTeamAmericano = async (email, id) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  let res = {}

  await axios.get(baseUrl + '/isInTournamentTeamAmericano?email=' + email + "&id=" + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response isInTournamentTeamAmericano in : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)

    });
  return res
}

export const leaveTournament = async (email, id, chatId, profilePic) => {
  const bodyconfig = {
    id: id,
    chatid: chatId,
    email: email,
    profilePic: profilePic
  }
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/leaveTournament', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in leaveTournament : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const removeInviteTournament = async (id, email) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/removeInviteTournament?id=' + id + '&email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get removeInviteTournament : ", response);
      return res;
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}

export const deleteAmericanoTeam = async (data) => {
  const bodyconfig = data;
  try {
    let res = await axios.post(baseUrl + '/deleteTeamAmericano', bodyconfig, headersconfig)
    console.log("get deleteTeamAmericano : ", res);
    return res.data

  } catch (error) {
    console.log(error)
  }
}

export const changeAmericanoTeamPlayer = async (data) => {
  const bodyconfig = data;
  try {
    let res = await axios.post(baseUrl + '/changeAmericanoTeamPlayer', bodyconfig, headersconfig)
    console.log("get changeAmericanoTeamPlayer : ", res);
    return res.data

  } catch (error) {
    console.log(error)
  }
}

export const requestToJoinTournament = async (id, user) => {
  const bodyconfig = {
    id: id,
    user: user
  }
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/requestToJoinTournament', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in requestTournament : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}

export const isRequested = async (email, id) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  let res = {}

  await axios.get(baseUrl + '/isRequested?email=' + email + "&id=" + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response isInTournament in : ", response);
      return res;
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)

    });
  return res
}

export const refundPayment = async (data) => {
  const bodyconfig = data;
  try {
    let res = await axios.post(baseUrl + '/refundPayment', bodyconfig, headersconfig)
    console.log("get refundPayment : ", res);
    return res.data

  } catch (error) {
    console.log(error)
  }
}

export const isRequestedteam = async (email, id) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  let res = {}

  await axios.get(baseUrl + '/isRequestedteam?email=' + email + "&id=" + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response isRequestedteam in : ", response);
      return res;
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)

    });
  return res
}

export const reCreateAmericanoGames = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/reCreateAmericanoGames?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl reCreateAmericanoGames : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
      res = undefined
    });
  return res;
}

export const deleteuserTournament = async (email, id, chatId, profilePic, mainEmail) => {
  const bodyconfig = {
    id: id,
    chatid: chatId,
    email: email,
    profilePic: profilePic,
    mainEmail: mainEmail
  }
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/deleteuserTournament', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in deleteuserTournament : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getAmericanoTournamentPlayers = async (id) => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getAmericanoTournamentPlayers?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAmericanoTournamentPlayers : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const joinTeamAmericanoTournament = async (id, user) => {
  console.log("*****************")
  const bodyconfig = {
    id: id,
    user: user
  }
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/joinTeamAmericanoTournament', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in joinTournament : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const registerResultTournament = async (id, obj, teams, type, matchId) => {
  const bodyconfig = {
    "id": id,
    "obj": obj,
    "teams": teams,
    "type": type,
    "matchId": matchId
  }
  console.log(bodyconfig)
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/registerResultTournament', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in registerResultTournament : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const updateResultTournament = async (id, obj, teams, teamsLast, type, matchId) => {
  const bodyconfig = {
    "id": id,
    "obj": obj,
    "teams": teams,
    "teamsLast": teamsLast,
    "type": type,
    "matchId": matchId
  }
  console.log(bodyconfig)
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/updateResultTournament', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateResultTournament : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const updateRequestTournament = async (id, email, obj) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  console.log(obj)
  const bodyconfig = JSON.stringify({
    id: id,
    email: email,
    requestsArr: obj
  })
  let res = {}
  await axios.post(baseUrl + '/updateRequestTournament', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved updateRequestTournament : ", response);
      return res;
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)

    });
  return res
}

export const updateTeamPlayer = async (id, obj) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  console.log(obj)
  const bodyconfig = JSON.stringify({
    id: id,
    obj: obj
  })
  let res = {}
  await axios.post(baseUrl + '/updateTeamPlayer', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved updateTeamPlayer : ", response);
      return res;
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)

    });
  return res
}

export const addNotiRequestToUser = async (email, obj) => {
  const bodyconfig = {
    "email": email,
    "obj": obj,
  }
  var res = {}
  await axios.post(baseUrl + '/addNotiRequestToUser', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in addNotiRequestToUser : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}

export const reCreatemakeRoundRobin = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/reCreatemakeRoundRobin?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllProduct : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
      res = undefined
    });
  return res;
}
export const createCustomer = async (email) => {

  var res = {}
  await axios.get(baseUrl + '/createCustomer?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl createCustomer : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
      res = undefined
    });
  return res;
}

export const reCreatemakeMaxicano = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/reCreatemakeMaxicano?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl reCreatemakeMaxicano : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
      res = undefined
    });
  return res;
}

export const declineTournamentRequest = async (id, email) => {
  const bodyconfig = {
    id: id,
    email: email
  }
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/declineTournamentRequest', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in requestTournament : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}

export const createSubscription = async (email, priceId) => {
  const bodyconfig = {
    email: email,
    priceId: priceId
  }
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/createSubscription', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createSubscription : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}

export const isInTournament = async (id) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  let res = {}

  await axios.get(baseUrl + '/isInTournament?email=' + token + "&id=" + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response isInTournament in : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)

    });
  return res
}


export const addNotiToUser = async (email, obj) => {
  const bodyconfig = {
    "email": email,
    "obj": obj,
  }
  var res = {}
  await axios.post(baseUrl + '/addNotiToUser', bodyconfig, headersconfig)
    .then(function (response) {
      // console.log("response recieved in addNotiToUser : " , response);
    })
    .catch(function (error) {
      // console.log(error)
    });
  return res;
}
export const getSimpleProfileForActivity = async () => {

  var res = {}
  await axios.get(baseUrl + '/getSimpleProfileForActivity?email=' + token, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getSimpleProfileForActivity : ", response);
    })
    .catch(function (error) {
      //  baseUrl =backup
      res = undefined
    });
  return res;
}

export const customNoti = async (title, desc, locations, level, lang) => {

  const bodyconfig = JSON.stringify({
    title: title,
    desc: desc,
    locations: locations,
    level: level,
    lang: lang
  })
  console.log(bodyconfig)
  var res = {}
  await axios.post(baseUrl + '/sendCustomNoti', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in : ", response);
    })
    .catch(function (error) {
      console.log(error)

    });
  return res;
}
export const sendEmailGet = async (title, desc, locations, level, lang) => {
  const bodyconfig = JSON.stringify({
    title: title,
    desc: desc,
    locations: locations,
    level: level,
    lang: lang
  })
  var res = []
  await axios.post(baseUrl + '/sendEmailGet', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("sendEmailGet recieved in : ", response);
      res = response.data
    })
    .catch(function (error) {
      console.log(error)

    });
  return res;
}

export const sendDouble = async (title, desc, activity, users) => {
  const bodyconfig = JSON.stringify({
    activity: activity,
    users: users,
    title: title,
    desc: desc,
  })
  var res = {}
  await axios.post(baseUrl + '/double', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in double : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });

  return res;
}



export const createCategory = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createCategory', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const createSubCategory = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createSubCategory', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const createProduct = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createProduct', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createProduct : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const createLocations = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createLocations', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createLocations : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const createOffer = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createOffer', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createOffer : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const createCommercial = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createCommercial', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createCommercial : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}



export const getFilterAcivities = async (locations, level, pgNo) => {

  var lev = []
  if (level[0]) {
    lev.push(level[0].name)
  }
  if (level[1]) {
    lev.push(level[1].name)
  }
  if (level[2]) {
    lev.push(level[2].name)
  }
  console.log('seeparams', locations, lev, pgNo)
  const bodyconfig = {
    "locations": locations,
    "level": lev,
    "pgNo": pgNo
  }
  var res = {}
  await axios.post(baseUrl + '/getFilterAcivities', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      // console.log("response recieved in getFilterAcivities : " , response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updateUser = async (email, obj) => {
  const bodyconfig = {
    "email": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateUser', bodyconfig, headersconfig)
    .then(function (response) {
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updateEarningField = async (email, obj) => {
  const bodyconfig = {
    "email": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateEarningField', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateUser : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const searchUsernameEmail = async (email) => {
  const bodyconfig = {
    "email": email
  }
  var res = {}
  await axios.post(baseUrl + '/searchUsernameEmail', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in searchUsernameEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updateCategory = async (email, obj) => {
  const bodyconfig = {
    "id": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateCategory', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const updateSubCategory = async (email, obj) => {
  const bodyconfig = {
    "id": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateSubCategory', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateSubCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const updateProduct = async (email, obj) => {
  const bodyconfig = {
    "id": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateProduct', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const updateCommercial = async (email, obj) => {
  const bodyconfig = {
    "id": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateCommercial', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateCommercial : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updateOffer = async (email, obj) => {
  const bodyconfig = {
    "id": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateOffer', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateOffer : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updateSupport = async (email, obj) => {
  const bodyconfig = {
    "id": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateSupport', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateSupport : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const updateLocation = async (email, obj) => {
  const bodyconfig = {
    "id": email,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateLocation', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateLocation : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getAllUser = async () => {

  var res = {}
  await axios.get(baseUrl + '/nononouserall', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl users : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const searchuser = async (text) => {

  var res = {}
  await axios.get(baseUrl + '/searchuser?q=' + text, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl searchUser : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getUserActivity = async (email) => {

  var res = {}
  await axios.get(baseUrl + '/getUserActivity?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl users : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getUserByEmailSpecial = async (email) => {

  var res = {}
 
  await axios.get(baseUrl + '/getuserbyemail?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserByEmail : ", response);
    })
    .catch(function (error) {
      res = undefined
    });
  return res;
}

export const getUserByEmail = async () => {

  var res = {}
  var email = token
  await axios.get(baseUrl + '/getuserbyemail?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserByEmail : ", response);
    })
    .catch(function (error) {
      res = undefined
    });
  return res;
}


export const getTournamentPlayerInfoByEmail = async () => {

  var res = {}
  var email = token
  await axios.get(baseUrl + '/getTournamentPlayerInfoByEmail?email=' + token, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getTournamentPlayerInfoByEmail : ", response);
    })
    .catch(function (error) {
      res = undefined
    });
  return res;
}

export const getUserActivity3 = async (email) => {

  var res = []
  await axios.get(baseUrl + '/getUserActivity2?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getUserActivity2 : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const getUserActivity2 = async (email) => {

  var res = []
  await axios.get(baseUrl + '/getUserActivity2?email=' + token || token, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getUserActivity2 : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}

export const createSupport = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createSupport', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createSupport : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const createActive = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createActivity', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createActive : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const createActivityNoti = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createActivityNoti', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createActivityNoti : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updateActivity = async (id, obj) => {
  const bodyconfig = {
    "id": id,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateActivity', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateActivity : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const deleteActivity = async (id) => {
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/deleteActivity', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in deleteActivity : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const deleteCategory = async (id) => {
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/deleteCategory', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in deleteCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const deleteSubCategory = async (id) => {
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/deleteSubCategory', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in deleteSubCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const deleteProduct = async (id) => {
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/deleteProduct', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in deleteProduct : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const deleteLocation = async (id) => {
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/deleteLocation', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in deleteLocation : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const changeSubscription = async (data) => {
  console.log(data)
  const bodyconfig = data
  var res = {}
  await axios.post(baseUrl + '/changeSubscription', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      // debugger;
      console.log("response recieved in changeSubscription : ", response);
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}

export const reCreateMixAmericano = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/reCreateMixAmericano?id='+id, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get ALl reCreateMixAmericano : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
        res = undefined
  });
  return res;
}
export const cancelSubscription = async (data) => {
  console.log(data)
  const bodyconfig = data
  var res = {}
  await axios.post(baseUrl + '/cancelSubscription', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in cancelSubscription : ", response);
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}


export const deleteOffer = async (id) => {
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/deleteOffer', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in deleteActivity : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}



export const deleteCommercial = async (id) => {
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/deleteCommercial', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in deleteCommercial : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}




export const getActivityByDateTime = async (date, time, endTime) => {

  var res = {}
  await axios.get(baseUrl + '/getActivityByDateTime?date=' + date + "&time=" + time + "&timeEnd=" + endTime, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get all getActivityByDateTime : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const filterUserForInvites = async (loca, level, q) => {
  const bodyconfig = {
    "locations": loca,
    "level": level,
    "q": q
  }

  console.log(bodyconfig)
  var res = {}
  await axios.post(baseUrl + '/filterUserForInvites', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in filterUserForInvites : ", response);
    })
    .catch(function (error) {

      console.log(error)
    });
  return res;
}
export const getUserPlayedActivity2 = async (email) => {
  const limit = 100;
  var res = {}
  await axios.get(`${baseUrl}/getUserPlayedActivity2?email=${email}&page=${0}&limit=${100}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserPlayedActivity2 : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const getUserPlayedActivity = async (email, page = 0) => {
  const limit = 200;
  var res = {}
  await axios.get(`${baseUrl}/getUserPlayedActivity2?email=${email || token}&page=${page}&limit=${limit}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserPlayedActivity2 : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const getUserCenterActivity = async (location) => {
  const limit = 100;
  var res = {}
  await axios.get(`${baseUrl}/getUserCenterActivity?locations=${location}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserCenterActivity : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const getUserCenterActivityPlayed = async (location) => {
  const limit = 100;
  var res = {}
  const bodyconfig = {
    "locations": location,
  }
  await axios.post(`${baseUrl}/getUserCenterActivityPlayed?locations=${location}`,bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserCenterActivityPlayed : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const getActivityById = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/getActivityById?id=' + id, headersconfig)
    .then(function (response) {
      if (response.data == '') {
        res = { error: "notfound" }
      }
      else {
        res = response.data
      }
      console.log("get all getActivityById : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getCommercialById = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/getCommercialById?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get all getCommercialById : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getOfferById = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/getOfferById?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get all getOfferById : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getLocationById = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/getLocationById?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get all getLocationById : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const paymentRequest = async () => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getpaymentRequest', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl paymentRequest : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const getpaymentRequestCenter = async () => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getpaymentRequestCenter', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getpaymentRequestCenter : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const getAllActivity = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllActivity', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllActivity : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}


export const getAllSupport = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllSupport', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllSupport : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}


export const getAllOffer = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllOffers', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllOffers : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}
export const getAllCommercial = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllCommercial', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllCommercial : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const getAllCategory = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllCategory', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}
export const getAllSubCategory = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllSubCategory', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllSubCategory : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const getCategoryById = async (id) => {

  // const headersconfig = {
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   }
  // }
  // var res = {}
  // await axios.get(baseUrl + '/getCategoryById', headersconfig)
  // .then(function (response) {
  //   res = response.data
  //       console.log("get ALl getCategoryById : " , response);
  // })
  // .catch(function (error) {
  //       //console.log(error)
  //       res = undefined
  // });
  // return res;

  var res = {}
  await axios.get(baseUrl + '/getCategoryById?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get all getCategoryById : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getSubCategoryById = async (id) => {

  // const headersconfig = {
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   }
  // }
  // var res = {}
  // await axios.get(baseUrl + '/getCategoryById', headersconfig)
  // .then(function (response) {
  //   res = response.data
  //       console.log("get ALl getCategoryById : " , response);
  // })
  // .catch(function (error) {
  //       //console.log(error)
  //       res = undefined
  // });
  // return res;

  var res = {}
  await axios.get(baseUrl + '/getSubCategoryById?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get all getSubCategoryById : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const getAllProduct = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllProduct', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllProduct : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const getProductById = async (id) => {



  var res = {}
  await axios.get(baseUrl + '/getProductById?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get all getCategoryById : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const getSpecialById = async (id) => {



  var res = {}
  await axios.get(baseUrl + '/getSpecialById?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get all getSpecialById : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const updateSpecial = async (id, obj) => {
  const bodyconfig = {
    "id": id,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updateSpecial', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateSpecial : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getActivityByDate = async (dates, locations) => {
  const bodyconfig = {
    "locations": locations,
    "dates": dates
  }
  var res = {}
  await axios.post(baseUrl + '/getActivityByDate', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in getActivityByDate : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}


export const getUserByDate = async (dates, locations) => {
  const bodyconfig = {
    "locations": locations,
    "dates": dates
  }
  var res = {}
  await axios.post(baseUrl + '/getUserByDate', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in getUserByDate : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getTournamentsByDate = async (dates, locations) => {
  const bodyconfig = {
    "locations": locations,
    "dates": dates,
    "email": token
  }
  var res = {}
  await axios.post(baseUrl + '/getTournamentsByDate', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in getUserByDate : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const downloadInvoice = async (body) => {
  const bodyconfig = body
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    }
  }
  var res = {}
  await axios.post(baseUrl + '/downloadInvoice', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in downloadInvoice : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const downloadInvoiceCenter = async (body) => {
  const bodyconfig = body
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    }
  }
  var res = {}
  await axios.post(baseUrl + '/downloadInvoiceCenter', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in downloadInvoiceCenter : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const makeTranfers = async (body) => {
  const bodyconfig = body
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    }
  }
  var res = {}
  await axios.post(baseUrl + '/makeTranfers', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in makeTranfers : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getActivityRealByDate = async (dates, locations) => {
  const bodyconfig = {
    "locations": locations,
    "dates": dates
  }
  var res = {}
  await axios.post(baseUrl + '/getActivityRealByDate', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in getActivityRealByDate : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getAllLocation = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllLocation', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getAllLocation : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const getUserCount = async () => {

  var res = {}
  await axios.get(baseUrl + '/getUserCount', headersconfig)
    .then(function (response) {
      if (response.data == 'unautharized') {
        localStorage.clear()
        window.location.href = './#/login'
      }
      res = response.data
      console.log("get ALl getUserCount : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}


export const getLocationKpi = async () => {

  var res = {}
  await axios.get(baseUrl + '/getLocationKpi', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getLocationKpi : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}


export const getUserFavKpi = async () => {

  var res = {}
  await axios.get(baseUrl + '/getUserFavKpi', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserFavKpi : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}


export const getApple = async () => {

  var res = {}
  await axios.get(baseUrl + '/getApple', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getApple : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const linkAccount = async (body) => {
  
  const bodyconfig = {id:body}
  var res = {}
  await axios.post(baseUrl + '/linkAccountweb', bodyconfig, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("response recieved in linkAccount : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });
  return res;
}
export const isVerifiedStrip = async (body) => {
  
  const bodyconfig = {id:body}
  var res = {}
  await axios.post(baseUrl + '/isVerifiedStrip', bodyconfig, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("response recieved in isVerifiedStrip : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });
  return res;
}
export const createAccount = async (email) => {
  const bodyconfig = {
    "email":email,
  }
  var res = {}
  await axios.post(baseUrl + '/createAccount', bodyconfig, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("response recieved in createAccount : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });
  return res;
}
export const getUserNotifications = async () => {

  var res = {}
  await axios.get(`${baseUrl}/getAllNotification?email=${token}&page=${0}&limit=${100}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserNotifications : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      res = undefined
    });
  return res;
}
export const getNews = async (n) => {
  var res = {}
  await axios.get("https://rss.aftonbladet.se/rss2/small/collections?tags=padel", headersconfig)
    .then(function (response) {
      //console.log("response recieved in : " , response);
      res = response
    })
    .catch(function (error) {
      //console.log(error)
      res = error
    });
  return res;
}
export const createUser = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createUser', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in createUser : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const requestforCenter = async (obj) => {
  console.log(obj)
  const bodyconfig = JSON.stringify(obj)
  let res = {}
  await axios.post(baseUrl + '/createCenterRequest', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in : ", response);
      return res;
    })
    .catch(function (error) {

      console.log(error)

    });
  return res
}

export const getNews1 = async (n) => {
  var res = {}
  await axios.get("https://padeldirekt.se/feed", headersconfig)
    .then(function (response) {
      //console.log("response recieved in : " , response);
      res = response
    })
    .catch(function (error) {
      //console.log(error)
      res = error
    });
  return res;
}

export const getSimpleProfile = async () => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getSimpleProfile?email=' + token, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getSimpleProfile : ", response);
    })
    .catch(function (error) {
      res = undefined
    });
  return res;
}

export const getSimpleProfile2 = async (email) => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getSimpleProfile?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getSimpleProfile : ", response);
    })
    .catch(function (error) {
      res = undefined
    });
  return res;
}

export const getUserProfileDataLit = async (email) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getUserProfileDataLit?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl getUserProfileDataLit : ", response);
    })
    .catch(function (error) {
      res = undefined
    });
  return res;
}

export const deleteUser = async (email) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/deleteUser', {
    email: email
  }, headersconfig)
    .then(function (response) {
      res = response.data
    })
    .catch(function (error) {
      res = undefined
    });
  return res;
}

export const changeNameLocation = async (newa, old, obj) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  try {
    let res = await axios.post(baseUrl + '/changeNameLocation', {
      new: newa,
      old: old,
      obj: obj,
    }, headersconfig)
    if (res) {
      console.log(res.data);
      return res.data
    }
  } catch (err) {
    console.log(err);
  }
}
export const getActivityDetailsByDate = async (_date) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  try {
    let res = await axios.post(baseUrl + '/getActivityByDateAdmin', {
      date: _date
    }, headersconfig)
    if (res) {
      console.log(res.data);
      return res.data
    }
  } catch (err) {
    console.log(err);
  }
}

export const getUserPerDateData = async (_date) => {
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  try {
    let res = await axios.post(baseUrl + '/getUserByDateAdmin', {
      date: _date
    }, headersconfig)
    if (res) {
      return res.data
    }
  } catch (err) {
    console.log(err);
  }
}

export const getAllCoachList = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllCoachList', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getAllCoachList : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getAllCenterRequestList = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllCenterRequestList', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getAllCenterRequestList : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getCoachByEmail = async (email) => {

  var res = {}
  await axios.get(baseUrl + '/getCoachByEmail?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getCoachByEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getCenterByEmail = async (email) => {

  var res = {}
  await axios.get(baseUrl + '/getCenterByEmail1?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getCenterByEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getCenterByEmailYo = async (email) => {

  var res = {}
  await axios.get(baseUrl + '/getCenterByEmailYo?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getCenterByEmailYo : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updateCoachField = async (email, data) => {

  var res = {}
  await axios.post(baseUrl + '/updateCoachField',
    {
      email: email,
      obj: data
    },
    headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get updateCoachField : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const updateCenterField = async (email, data) => {

  var res = {}
  await axios.post(baseUrl + '/updateCenterField',
    {
      email: email,
      obj: data
    },
    headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get updateCenterField : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getApprovedCoachList = async () => {
  var res = {}
  await axios.get(baseUrl + '/getApprovedCoachList', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getApprovedCoachList : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getCentersList = async () => {
  var res = {}
  await axios.get(baseUrl + '/getCentersList', headersconfig)
    .then(function (response) {
      res = response.data
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}



export const getApprovedCoachByEmail = async (email) => {
  var res = {}
  await axios.get(baseUrl + '/getApprovedCoachByEmail?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getApprovedCoachByEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getTournamentsOfCenterByEmail = async (email) => {
  var res = {}
  await axios.get(baseUrl + '/getTournamentsOfCenterByEmail?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getTournamentsOfCenterByEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getTournamentById = async (id) => {
  var res = {}
  await axios.get(baseUrl + '/getTournamentById?id=' + id, headersconfig)
    .then(function (response) {
      if (response.data == '') {
        res = { error: "notfound" }
      }
      else {
        res = response.data
      }
      console.log("get getTournamentById : ", response);
    })
  return res;
}
export const searchVerifiedCoachByEmail = async (email) => {
  const bodyconfig = {
    "email": email
  }
  var res = {}
  await axios.post(baseUrl + '/searchVerifiedCoachByEmail', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in searchVerifiedCoachByEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getVerifiedCoachSessionsByEmail = async (email) => {
  const bodyconfig = {
    "email": email
  }
  var res = {}
  await axios.post(baseUrl + '/getVerifiedCoachSessionsByEmail', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in getVerifiedCoachSessionsByEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getAllCoachEarnings = async (email) => {
  try {
    const [
      completedEarnings,
      upcomingEarnings,
      earningByEmail
    ] = await Promise.all([
      axios.get(baseUrl + '/getCompletedEarning?email=' + email, headersconfig),
      axios.get(baseUrl + '/getUpcommingEarning?email=' + email, headersconfig),
      axios.get(baseUrl + '/getEarningByEmail?email=' + email, headersconfig),
    ])

    console.log(completedEarnings)
    console.log(upcomingEarnings)
    console.log(earningByEmail)

    const response = {
      completed: completedEarnings.data.amount,
      upcoming: upcomingEarnings.data.amount,
      other: {
        withdraw: earningByEmail.data.withdraw,
        clearance: earningByEmail.data.clearence,
        percentage: earningByEmail.data.percentage
      }
    }
    return response;

  } catch (error) {
    console.error(error)
  }
}

export const getAllTournaments = async () => {
  var res = {}
  await axios.get(baseUrl + '/getAllTournament', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getAllTournament : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getTournamentPlayers = async (id) => {
  var res = {}
  await axios.get(baseUrl + '/getTournamentPlayers', {
    params: {
      id
    }
  }, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getTournamentPlayers : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}



export const getTournamentMatches = async (id, type) => {
  var res = {}
  await axios.get(baseUrl + '/getTournamentMatches', {
    params: {
      id: id,
      type: type
    }
  }, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getTournamentMatches : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getTeamAmericanoMatches = async (id, type) => {
  var res = {}
  await axios.get(baseUrl + '/getTeamAmericanoMatches', {
    params: {
      id: id,
      type: type
    }
  }, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getTeamAmericanoMatches : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getUpcomingTournaments = async (email) => {
  var res = {}
  await axios.get(baseUrl + '/getUpcomingTournaments?email=' + token || token, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getUpcomingTournaments : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}
export const getUpcomingTournaments2 = async (email) => {
  var res = {}
  await axios.get(baseUrl + '/getUpcomingTournaments?email=' + email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getUpcomingTournaments : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}
export const getPlayedTournaments2 = async (email) => {
  var res = {}
  await axios.get(baseUrl + '/getPlayedTournaments?email='+email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getPlayedTournaments : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}

export const getPlayedTournaments = async (email) => {
  var res = {}
  await axios.get(baseUrl + '/getPlayedTournaments?email=' + token || token, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getPlayedTournaments : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}

export const getTournamentsOfCenter = async (location) => {
  var res = {}
  const bodyconfig = {
    locations: location,
  }
  await axios.post(baseUrl + '/getUserCenterTournament?locations='+location,bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getTournamentsOfCenter : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}

export const createTournament = async (body, user) => {
  const bodyconfig = {
    main: body,
    user: user
  }
  console.log(bodyconfig)
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/createTournament', bodyconfig, headersconfig)
    .then(function (response) {

      res = response.data


      console.log("response recieved in createTournament : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const createChannel = async (body) => {
  const bodyconfig = body
  var res = {}
  await axios.post(baseUrl + '/createChannel', bodyconfig, headersconfig)
  .then(function (response) {
    res =response.data
        console.log("response recieved in createChannel : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log("response recieved in createChannel ",error)
  });
  return res;
}
export const updateTournament = async (body) => {
  const bodyconfig = body
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/updateTournament', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in updateTournament : ", response);
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}
export const getEarningMonthlyCenter = async (data) => {

  var body = {
    email: data.email,
    id: data.accountId
  }
  //  console.log('seeparams',locations,lev,pgNo)
  const bodyconfig = body
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/getEarningMonthlyCenter', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in getEarningMonthlyCenter : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}

export const getTeamAmericanoPlayers = async (id) => {
  var res = {}
  await axios.get(baseUrl + '/getAmericanoTournamentPlayers', {
    params: {
      id
    }
  }, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get Team Americano Players : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getAllPosts = async () => {

  var res = {}
  await axios.get(baseUrl + '/getAllPosts?email='+token, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get ALl Posts : ", response);
    })
    .catch(function (error) {
      //console.log(error)
      res = undefined
    });
  return res;
}

export const createPost = async (data) => {


  //  console.log('seeparams',locations,lev,pgNo)
  const bodyconfig = data
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/createPost', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("Post Created : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}

export const getPostById = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/getPostById?id=' + id, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get posts by Id : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updatePost = async (id, obj) => {
  //debugger;
  const bodyconfig = {
    "id": id,
    "obj": obj
  }
  var res = {}
  await axios.post(baseUrl + '/updatePost', bodyconfig, headersconfig)
    .then(function (response) {
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const deletePost = async (id) => {
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/deletePost', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in Delete Post : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getUserCards = async (id) => {
  const bodyconfig = {
    "uid": id,
  }
  var res = {}
  await axios.post(baseUrl + '/getUserCards', bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("response recieved in getUserCards Post : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const sendPostNoti = async (id) => {
  //debugger;
  const bodyconfig = {
    "id": id,
  }
  var res = {}
  await axios.post(baseUrl + '/sendPostNoti', bodyconfig, headersconfig)
    .then(function (response) {

    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getActivityCountByEmail = async () => {

  var res = {}
  await axios.get(baseUrl + '/getActivityCountByEmail', {
    params: {
      email: token
    }
  }, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getActivityCountByEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const replaceCard = async (cid, pid, subid) => {
  var res = {}
  await axios.get(baseUrl + '/replaceCard?cid=' + cid + "&pmid=" + pid + "&subid=" + subid, {
    params: {
      email: token
    }
  }, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("replaceCard : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getTournamentCountByEmail = async () => {
  var res = {}
  await axios.get(baseUrl + '/getTournamentCountByEmail', {
    params: {
      email: token
    }
  }, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getTournamentCountByEmail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getStatisticsSummary = async () => {
  try {
    const [
      activityCount,
      tournamentCount,
      // earningByEmail
    ] = await Promise.all([
      axios.get(baseUrl + '/getActivityCountByEmail?email=' + token, headersconfig),
      axios.get(baseUrl + '/getTournamentCountByEmail?email=' + token, headersconfig),
      // axios.get(baseUrl + '/getEarningByEmail?email='+email, headersconfig),
    ])

    const response = {
      activityCount: activityCount.data.total,
      tournamentCount: tournamentCount.data.total,
    }
    return response;
  } catch (error) {
    console.log(error)
  }
}

export const getCenterDashbordOverview = async (location) => {
  var res = {}
  const bodyconfig = {location : location}
  await axios.post(baseUrl + '/getCenterDashbordOverview?location=' + location,bodyconfig, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getCenterDashbordOverview : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getCentertournamentGraph = async (location, startDate, endDate) => {
  var res = {}
  await axios.get(baseUrl + `/getCentertournamentGraph?location=${location}&start=${startDate}&end=${endDate}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getCentertournamentGraph : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getCentertournamentGraphClub = async (location, startDate, endDate) => {
  var res = {}
  await axios.get(baseUrl + `/getCentertournamentGraphClub?email=${location}&start=${startDate}&end=${endDate}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getCentertournamentGraphClub : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getCenterActivityGraph = async (location, startDate, endDate) => {
  var res = {}
  await axios.get(baseUrl + `/getCenterActivityGraph?location=${location}&start=${startDate}&end=${endDate}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getCenterActivityGraph : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getCenterFavTrail = async (location, startDate, endDate) => {
  var res = {}
  await axios.get(baseUrl + `/getCenterFavTrail?location=${location}&start=${startDate}&end=${endDate}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getCenterFavTrail : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}



export const getCenterActivityGraphClub = async (location, startDate, endDate) => {
  var res = {}
  await axios.get(baseUrl + `/getCenterActivityGraphClub?email=${location}&start=${startDate}&end=${endDate}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getCenterActivityGraph : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getActivityPlayedGraph = async (location, startDate, endDate) => {
  var res = {}
  await axios.get(baseUrl + `/getActivityPlayedGraph?location=${location}&start=${startDate}&end=${endDate}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getActivityPlayedGraph : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getTournamentsPlayedGraph = async (location, startDate, endDate) => {
  var res = {}
  await axios.get(baseUrl + `/getTournamentsPlayedGraph?location=${location}&start=${startDate}&end=${endDate}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getTournamentsPlayedGraph : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getPlayerInfoForCenter = async (location) => {
  var res = {}
    //debugger;
    const bodyconfig = {
      "location": location,
    }
  await axios.post(baseUrl + `/getPlayerInfoForCenter`, bodyconfig,headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getPlayerInfoForCenter : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getActivityStartTimeGraph = async (location, startDate, endDate) => {
  var res = {}
  await axios.get(baseUrl + `/getActivityStartTimeGraph?location=${location}&start=${startDate}&end=${endDate}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getActivityStartTimeGraph : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}
export const getUserRating = async (email) => {
  var res = []
  await axios.get(baseUrl + '/getUserRating?email='+email, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get ALl getUserRating : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });
  return res;
}

export const getInvoices = async (email) => {
  var res = {}
  await axios.get(baseUrl + `/getInvoices?email=${email}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getInvoices : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getUserFollowing = async (email) => {
  var res = {}
  await axios.get(baseUrl + `/getUserFollowing?email=${email}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getUserFollowing : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getUserFollowers = async (email) => {
  var res = {}
  await axios.get(baseUrl + `/getUserFollowers?email=${email}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getUserFollowers : ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const invoiceRequest = async (email, companyName , companyEmail) => {
  const bodyconfig = {
    email: email,
    companyName: companyName,
    companyEmail: companyEmail
  }
  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.post(baseUrl + '/createInvoiceRequest', bodyconfig, headersconfig)
    .then(function (response) {
      console.log("response recieved in Create Invoice : ", response);
    })
    .catch(function (error) {
      // baseUrl =backup
      console.log(error)
    });
  return res;
}
export const reStartExtra = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/reStartExtra?id='+id, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get ALl reStartExtra : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
        res = undefined
  });
  return res;
}
export const reStartExtraTeam = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/reStartExtraTeam?id='+id, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get ALl reStartExtraTeam : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
        res = undefined
  });
  return res;
}

export const makeFinalSemiFinal = async (id,type) => {

  var res = {}
  await axios.get(baseUrl + '/makeFinalSemiFinal?id='+id+"&extraRound="+type, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get ALl makeFinalSemiFinal : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
        res = undefined
  });
  return res;
}
export const getTournamentMatchesExtraTeam = async (id,type) => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getTournamentMatchesExtraTeam',{
    params:{
      id:id,
      type:type
    }
  })
  .then(function (response) {
    res = response.data
        console.log("get ALl getTournamentMatchesExtraTeam : " , response);
  })
  .catch(function (error) {
        //console.log(error)
        res = undefined
  });
  return res;
}
export const getTournamentMatchesExtra = async (id,type) => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getTournamentMatchesExtra',{
    params:{
      id:id,
      type:type
    }
  })
  .then(function (response) {
    res = response.data
        console.log("get ALl getTournamentMatchesExtra : " , response);
  })
  .catch(function (error) {
        //console.log(error)
        res = []
  });
  return res;
}

export const getPlayedTraining = async (email) => {
  var res = {}
  await axios.get(baseUrl + '/getPlayedTraining?email='+email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getPlayedTraining : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}

export const getUpcomingTraining = async (email) => {
  var res = {}
  await axios.get(baseUrl + '/getUpcomingTraining?email='+email, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("get getUpcomingTraining : ", response);
      return res;
    })
    .catch(function (error) {
      console.log(error)
    });
  return res;
}

export const getAvailableRewards = async () => {
  var res = {}
  await axios.get(baseUrl + '/getRewards', headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getAvailableRewards: ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const getRewardProducts = async (centerLocation) => {
  var res = {}
  await axios.get(baseUrl + `/getRewardItems?centerLocation=${centerLocation}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getRewardProducts: ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}



export const postRewardItems = async (name , description , image , status , coins , centerLocation) => {
  const bodyconfig = {name , descrption: description , image , Status: status , coins , centerLocation}
  let res ={}
   await axios.post(baseUrl + '/addRewardItems', bodyconfig, headersconfig)
  .then(function (response) {
        res = response
        console.log("postRewardItems : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}

export const getSingleProduct = async (item_id) => {
  var res = {}
  await axios.get(baseUrl + `/getRewardItem?item_id=${item_id}`, headersconfig)
    .then(function (response) {
      res = response.data
      console.log("getRewardProducts: ", response);
    })
    .catch(function (error) {
      //console.log(error)
    });
  return res;
}

export const updateRewardItem = async (description , image , status , coins , centerLocation , item_id,update) => {
  
  const bodyconfig = {descrption: description , image , Status: status , coins , centerLocation,update:true,item_id:item_id}
  let res ={}
   await axios.post(baseUrl + `/updateRewardItem?item_id=${item_id}`, bodyconfig, headersconfig)
  .then(function (response) {
        res = response
        console.log("updateRewardItem : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}

export const deleteRewardItem = async (item_id) => {
  const bodyconfig = {item_id}
  let res ={}
   await axios.post(baseUrl + `/deleteRewardItem`, bodyconfig, headersconfig)
  .then(function (response) {
        res = response
        console.log("deleteRewardItem : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}

export const getUsersWithRewardDetailsByCenter = async (centerLocation) => {
  const bodyconfig = {centerLocation}
  let res ={}
   await axios.post(baseUrl + `/getUsersWithRewardDetailsByCenter`, bodyconfig, headersconfig)
  .then(function (response) {
        res = response
        console.log("getUsersWithRewardDetailsByCenter : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}

export const getEarnLog = async (email,centerLocation) => {
  const bodyconfig = {email:email,centerLocation:centerLocation}
  let res ={}
   await axios.post(baseUrl + `/getEarnLog`, bodyconfig, headersconfig)
  .then(function (response) {
        res = response
        console.log("getEarnLog : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}
export const getMonthStates = async (centerLocation) => {
  let res ={}
   await axios.get(baseUrl + `/getMonthStates?centerLocation=`+centerLocation, headersconfig)
  .then(function (response) {
        res = response
        console.log("getMonthStates : " , response);
        return res.data;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}
export const earnstats = async (centerLocation) => {

  const headersconfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  var res = {}
  await axios.get(baseUrl + '/getEarnStats',{
    params:{
      centerLocation:centerLocation,
    }
  })
  .then(function (response) {
    res = response.data
        console.log("get ALl getEarnStats : " , response);
  })
  .catch(function (error) {
        //console.log(error)
        res = undefined
  });
  return res;
}
export const getLocationByName = async (id) => {

  var res = {}
  await axios.get(baseUrl + '/getLocationByName?name='+id, headersconfig)
  .then(function (response) {
    res = response.data
        console.log("get all getLocationById : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });
  return res;
}

export const removeEarnedCoins = async (email,typeId,type) => {
  const bodyconfig = {
    "email":email,
    'typeId':typeId,
    type:type
  }
  var res = {}
  await axios.post(baseUrl + '/removeEarnedCoins', bodyconfig, headersconfig)
  .then(function (response) {
        console.log("response recieved in removeEarnedCoins : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });
  return res;
}
export const getRewardHistory = async (centerLocation , startDate , endDate , rewardItems) => {
  const bodyconfig = {centerLocation ,  startDateTime : startDate , endDateTime: endDate , item_ids : rewardItems}
  let res ={}
   await axios.post(baseUrl + `/getRedeemedRewardsHistory`, bodyconfig, headersconfig)
  .then(function (response) {
        res = response
        console.log("getRedeemedRewardsHistory : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}

export const earnRewardCoins = async (matchType,centerLocation,email,typeId,coins) => {
  const bodyconfig = {
    "matchType":matchType,
    "centerLocation":centerLocation,
    "email":email,
    "typeId":typeId,
    // 'coins':coins
  }
  var res = {}
  await axios.post(baseUrl + '/earnRewardCoins', bodyconfig, headersconfig)
  .then(function (response) {
        console.log("response recieved in earnRewardCoins : " , response);
  })
 .catch(function (error) {
   // baseUrl =backup
        console.log(error)
  });
  return res;
}
export const addDefaultRewardItems = async (centerLocation) => {
  const bodyconfig = {
    centerLocation:centerLocation
  }
  let res ={}
   await axios.post(baseUrl + `/addDefaultRewardItems`, bodyconfig, headersconfig)
  .then(function (response) {
        res = response
        console.log("addDefaultRewardLocation : " , response);
        return res;
  })
 .catch(function (error) {

        console.log(error)

  });
  return res
}